(function ($) {

    // Handling Form Submit
    $('form').submit(function (e) {
        e.preventDefault();
        const form = $(this),
            msgArea = $(form).find('.form-result'),
            formData = $(form).serialize(),
            url = ' http://gospechatnik.koinkraft.com/dist/assets/php/sendemail.php';
        $(form).find('input[type="text"], input[type="checkbox"], textarea').removeClass('error');
        $.ajax({
            type: 'POST',
            url: url,
            data: formData
        })
            .done(function (response) {
                $(msgArea).html('<span>' + response + '</span>');
                $(msgArea).slideDown(330);
                $(form).find('input[type="text"], textarea').val('');
            })
            .fail(function (data) {
                $(msgArea).slideUp(330);
                const obj = JSON.parse(data.responseText);
                //console.log(obj);
                for (let key in obj) {
                    $(form).find('[name="' + obj[key] + '"]').addClass('error');
                }
            });
    });

    // About Typography Slider
    let aboutSwiper = new Swiper('.about-media-swiper', {
        direction: 'horizontal',
        loop: false,
        slidesPerView: 1,
        initialSlide: 0,
        pagination: {
            el: '.about-media-pagination',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.about-media-next',
            prevEl: '.about-media-prev',
        },
        scrollbar: {
            el: '.about-media-scrollbar'
        },
    });

    // Input Mask
    $(".mask-phone").mask("+7 (999) 999-99-99");

    // Anchor Links
    $(".header-nav a").click(function (e) {
        e.preventDefault();
        let hash = this.hash;
        $('html, body').animate(
            {
                scrollTop: $($(this).attr('href')).offset().top,
            },
            500,
            'linear'
        )
    });


    // Production Links
    $(".production-nav-link").click(function (e) {
        e.preventDefault();
        let prodLinkHash = this.hash;
        $('html, body').animate(
            {
                scrollTop: $($(this).attr('href')).offset().top,
            },
            500,
            'linear'
        )
    });


    let lastProdID,
        prodMenu = $("#prod-nav"),
        prodMenuItems = prodMenu.find(".production-nav-link"),
        prodMediaItems = $('.production-media').find('.production-media-item'),
        // Anchors corresponding to menu items

        prodScrollItems = prodMenuItems.map(function () {
            let prodItem = $($(this).attr("href"));
            if (prodItem.length) {
                return prodItem;
            }
        });

    // Bind to scroll
    $(window).scroll(function () {
        let prodFromTop = $(this).scrollTop(),
            prodCurrent = prodScrollItems.map(function (i) {
                if (prodScrollItems[i].offset().top - 450 < prodFromTop)
                    return prodScrollItems[i];
            });

        prodCurrent = prodCurrent[prodCurrent.length - 1]
        let prodID = prodCurrent && prodCurrent.length ? prodCurrent[0].id : "";

        if (lastProdID !== prodID) {
            lastProdID = prodID;
            console.log(prodID);
            prodMenuItems.removeClass("active");
            prodMediaItems.removeClass("active");
            $('.production-nav-link[href="#' + prodID + '"]').addClass('active');
            $('.production-media-item[data-media="' + prodID + '"]').addClass('active');

        }
    });


    // Button Hover Effect
    $('.btn')
        .on('mouseenter', function (e) {
            let parentOffset = $(this).offset(),
                relX = e.pageX - parentOffset.left,
                relY = e.pageY - parentOffset.top;
            $(this).find('.btn-effect').css({top: relY, left: relX});
        })
        .on('mouseout', function (e) {
            let parentOffset = $(this).offset(),
                relX = e.pageX - parentOffset.left,
                relY = e.pageY - parentOffset.top;
            $(this).find('.btn-effect').css({top: relY, left: relX});
        });


    // Header Smooth Color Change
    let headerWhite = $('.header--white'),
        headerBlack = $('.header--black');

    function headerColorSmooth() {
        headerWhite.css('top', headerBlack.offset().top);
    }

    headerColorSmooth();
    $(window).scroll(function () {
        headerColorSmooth()
    });


    // Cache selectors
    let lastId,
        topMenu = $("#header"),
        menuItems = topMenu.find(".header-nav-link"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function () {
            let item = $($(this).attr("href"));
            if (item.length) {
                return item;
            }
        });

    // Bind to scroll
    $(window).scroll(function () {
        let fromTop = $(this).scrollTop();
        let cur = scrollItems.map(function () {
            if ($(this).offset().top < fromTop)
                return this;
        });
        cur = cur[cur.length - 1];
        let id = cur && cur.length ? cur[0].id : "";
        if (lastId !== id) {
            lastId = id;
            //console.log(id);
            menuItems.removeClass("active");
            $('.header-nav-link[href="#' + id + '"]').addClass('active');
        }
    });


    // Modal Window
    let modalTrigger = $('.modal-trigger'),
        modalOverlay = $('.modal-overlay'),
        modalClose = $('.modal-close');

    modalTrigger
        .on('click', function (e) {
            e.preventDefault();
            let selectedModal = $(this),
                dataModal = selectedModal.attr('data-modal');
            modalOverlay.addClass('visible active');
            $('.modal[data-modal="' + dataModal + '"]').addClass('active');
            $('body').addClass('fix-height');
        });

    modalOverlay
        .on('click', function (e) {
            e.preventDefault();
            $('.modal').removeClass('active');
            $('body').removeClass('fix-height');
            setTimeout(function () {
                modalOverlay.removeClass('active');
            }, 200);
            setTimeout(function () {
                modalOverlay.removeClass('visible');
            }, 500);
        });

    modalClose
        .on('click', function (e) {
            e.preventDefault();
            $('.modal').removeClass('active');
            $('body').removeClass('fix-height');
            setTimeout(function () {
                modalOverlay.removeClass('active');
            }, 200);
            setTimeout(function () {
                modalOverlay.removeClass('visible');
            }, 500);
        });

    $('.pswp-trigger').on('click', function (e) {
        e.preventDefault();
        let pswpDataIndex = $(this).attr('data-index');
        openPhotoSwipe(pswpDataIndex);
    });


    let openPhotoSwipe = function (dataIndex) {
        let pswpElement = document.querySelectorAll('.pswp')[0];
        let options = {
            index: dataIndex,
            closeEl: true,
            captionEl: true,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            counterEl: true,
            arrowEl: true,
            preloaderEl: false,
            showAnimationDuration: 0,
            hideAnimationDuration: 0
        };
        let gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, pswp_slides, options);
        gallery.init();
    }


    let dropDown = $('[data-object="dropdown"]');
    dropDown.each(function(){
        let trigger = $(this).find('[data-object="dropdown-trigger"]'),
            content = $(this).find('[data-object="dropdown-content"]'),
            active  = false;
        trigger.on('click', function(e){
            e.preventDefault();
            if ( active === false ) {
                content.addClass('active');
                content.stop().slideDown(250);
                active = true;
            } else {
                content.removeClass('active');
                content.stop().slideUp(250);
                active = false;
            }

        });
    });


})(jQuery);